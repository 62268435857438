import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

// Formik Validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

//redux
import { useDispatch, useSelector } from 'react-redux';

import withRouter from 'Components/Common/withRouter';

//Import Breadcrumb
import Breadcrumb from 'Components/Common/Breadcrumb';

import { createSelector } from 'reselect';
import { editProfile, resetProfileFlag } from 'slices/thunk';

const UserProfile = () => {
  //meta title
  document.title = 'Profile | Plan Data AI';

  const dispatch = useDispatch<any>();

  const [email, setEmail] = useState('admin@gmail.com');
  const [name, setName] = useState(null);
  const [idx, setIdx] = useState(1);

  const selectProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      user: profile.user,
      error: profile.error,
      success: profile.success,
    }),
  );

  const { error, success, user } = useSelector(selectProperties);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const storedUser: any = localStorage.getItem('authUser');
      const obj = JSON.parse(storedUser);
      setName(user.username || obj.displayName);
      setEmail(obj.email);
      setIdx(obj.uid);

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, user]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: name,
      idx: idx || 1,
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your UserName'),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title='PDAI' breadcrumbItem='Profile' />

          <Row>
            <Col lg='12'>
              {error && error ? <Alert color='danger'>{error}</Alert> : null}
              {success ? <Alert color='success'>{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className='d-flex'>
                    <div className='flex-grow-1 align-self-center'>
                      <div className='text-muted'>
                        <h5>{name || 'admin'}</h5>
                        <p className='mb-1'>{email || 'admin@gmail.com'}</p>
                        <p className='mb-0'>Id no: #{idx || 1}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className='card-title mb-4'>Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className='form-horizontal'
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className='form-group'>
                  <Label className='form-label'>User Name</Label>
                  <Input
                    name='username'
                    className='form-control'
                    placeholder='Enter User Name'
                    type='text'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ''}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type='invalid'>{validation.errors.username}</FormFeedback>
                  ) : null}
                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className='text-center mt-4'>
                  <Button type='submit' color='primary'>
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
