import { ProvisionAnswer } from '@pdai/shared';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from 'firebase/auth';

import {
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

export interface AdoptionAgreement {
  id: string;
  userId: string;
  fileName: string;
  fileUri: string;
  downloadUrl: string;
  answers: ProvisionAnswer[];
  prompt: string;
  status: string;
  processedAt: string;
}

class FirebaseHelper {
  readonly storage;
  readonly auth;
  readonly db;
  readonly functions;
  readonly planFilesCollection;

  constructor(firebaseConfig: any) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);

      this.storage = getStorage(app);
      this.auth = getAuth(app);
      this.functions = getFunctions(app);

      // Initialize Cloud Firestore and get a reference to the service
      this.db = getFirestore(app);
      this.planFilesCollection = collection(this.db, 'planFiles');

      onAuthStateChanged(this.auth, (user: any) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
        } else {
          localStorage.removeItem('authUser');
        }
      });
    }
  }

  registerUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  // Basic document analysis
  async editProfileAPI(userName: string): Promise<any> {
    try {
      const currentUser = this.auth.currentUser;
      if (currentUser) {
        await updateProfile(currentUser, { displayName: userName });
        return userName;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error editing profile username:', error);
      throw error;
    }
  }

  loginUser = async (email: any, password: any) => {
    const beforeSignInEnforcer = httpsCallable(this.functions, 'beforeSignInEnforcer');
    await beforeSignInEnforcer({ email, password });

    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  forgetPassword = (email: any) => {
    return new Promise((resolve, reject) => {
      console.log(
        'forgetPassword',
        window.location.protocol + '//' + window.location.host + '/login',
      );
      sendPasswordResetEmail(this.auth, email, {
        url: window.location.protocol + '//' + window.location.host + '/login',
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          console.log('error:', error);
          reject(this._handleError(error));
        });
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(this._handleError(error));
        });
    });
  };

  socialLoginUser = async (type: any) => {
    let provider: any;
    if (type === 'google') {
      provider = new GoogleAuthProvider();
    } else if (type === 'facebook') {
      provider = new FacebookAuthProvider();
    }
    try {
      const result = await signInWithPopup(this.auth, provider);
      const user = result.user;
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  addNewUserToFirestore = (user: any) => {
    const collection: any = this.db.collection('users');
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: this.db.FieldValue.serverTimestamp(),
      lastLoginTime: this.db.FieldValue.serverTimestamp(),
    };
    collection.doc(this.auth.currentUser.uid).set(details);
    return { user, details };
  };

  // Upload File to Firebase Storage with Progress Updates
  async uploadFileToStorage(filePath: string, file: File, onProgress: (progress: number) => void) {
    return new Promise<string>((resolve, reject) => {
      const storageRef = ref(this.storage, filePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        },
      );
    });
  }

  // Basic document analysis
  async processFile(fileUrl: string): Promise<any> {
    try {
      const processFileFunction = httpsCallable(this.functions, 'processUploadedFile', {
        timeout: 5 * 60 * 1000,
      });

      // Get the currently authenticated user
      const currentUser = this.auth.currentUser;

      // Get the user's ID token
      const token = await currentUser?.getIdToken();

      if (!token) {
        throw new Error('User not authenticated');
      }

      const result = await processFileFunction({ fileName: fileUrl, authToken: token });
      return result.data;
    } catch (error) {
      console.error('Error processing file:', error);
      throw error;
    }
  }

  async listAllAdoptionAgreements(): Promise<AdoptionAgreement[]> {
    try {
      const currentUser = this.getCurrentUser();

      // Query Firestore for documents where userId matches the current user's UID
      const q = query(
        collection(this.db, 'adoptionAgreements'),
        where('userId', '==', currentUser.uid),
        orderBy('processedAt', 'desc'),
      );
      const querySnapshot = await getDocs(q);

      // Generate download URLs for each file
      const agreementsWithUrls = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const agreementData = doc.data() as AdoptionAgreement;
          const storageRef = ref(
            this.storage,
            agreementData.fileUri.replace('gs://plan-data-ai.appspot.com/', ''),
          );
          const downloadUrl = await getDownloadURL(storageRef);

          return {
            ...agreementData,
            id: doc.id,
            downloadUrl, // Update the downloadUrl
            processedAt: (agreementData.processedAt as unknown as Timestamp).toDate().toISOString(),
          };
        }),
      );

      return agreementsWithUrls;
    } catch (error) {
      console.error('Error listing adoption agreements:', error);
      throw error;
    }
  }

  async listAllAdoptionAgreementsIds(): Promise<string[]> {
    return this.listAllAdoptionAgreements().then((agreements) =>
      agreements.map((agreement: any) => agreement.id),
    );
  }

  async listByStatus(status: string): Promise<AdoptionAgreement[]> {
    try {
      const currentUser = this.getCurrentUser();
      const q = query(
        collection(this.db, 'adoptionAgreements'),
        where('status', '==', status),
        where('userId', '==', currentUser.uid),
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        ...(doc.data() as AdoptionAgreement),
        id: doc.id,
      }));
    } catch (error) {
      console.error('Error listing adoption agreements by status:', error);
      throw error;
    }
  }

  async updateAdoptionAgreement(
    id: string,
    updatedData: Partial<AdoptionAgreement>,
  ): Promise<Partial<AdoptionAgreement>> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const docRef = doc(this.db, 'adoptionAgreements', id);
        await updateDoc(docRef, { ...updatedData });
        return { id, ...updatedData };
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating adoption agreement:', error);
      throw error;
    }
  }

  getCurrentUser() {
    const user = localStorage.getItem('authUser');

    if (!user) {
      throw new Error('User not authenticated');
    }
    return JSON.parse(user);
  }

  _handleError(error: any) {
    const errorMessage = error.message;
    return errorMessage;
  }
}

export { FirebaseHelper };
