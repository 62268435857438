import { ProvisionAnswer } from '@pdai/shared';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import * as XLSX from 'xlsx';
import ProvisionRow from './provision-row';

interface ViewAnswersModalProps {
  isOpen: boolean;
  toggle: () => void;
  provisionsAnswers: ProvisionAnswer[];
}

const ViewAnswersModal: React.FC<ViewAnswersModalProps> = ({
  isOpen,
  toggle,
  provisionsAnswers,
}) => {
  const categories = [
    'Plan',
    'Sponsor/Employer',
    'Minimum Age and Service Requirements',
    'Contributions',
    'Annual Compliance',
    'Retirement',
    'Vesting and Forfeitures',
    'Distribution',
    'Loan',
  ];
  const onSaveFeedback = () => {};

  const handleDownloadExcel = () => {
    // Add a title row
    const data = [
      { Provisions: '', Answer: '' },
      ...provisionsAnswers.map((answer) => ({
        Provisions: answer.provisionLabel,
        Answer: answer.modelAnswer,
      })),
    ];

    const ws = XLSX.utils.json_to_sheet(data);

    // Make the title bold
    ws['A1'].s = {
      font: { bold: true },
    };
    // Make the title bold
    ws['A2'].s = {
      font: { bold: true },
    };

    // Auto-resize columns
    const colWidths = ['Provisions', 'Answer'].map((header) => ({
      wch: Math.max(...data.map((row) => (row[header] || '').toString().length)),
    }));
    ws['!cols'] = colWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Provisions Answers');
    XLSX.writeFile(wb, 'provisions_answers.xlsx');
  };

  return (
    <Modal size='lg' isOpen={isOpen} toggle={toggle} className='modal-answers'>
      <ModalHeader toggle={toggle}>
        <span>Provisions</span>
      </ModalHeader>
      <ModalBody>
        <div className='testClass'>
          <Button color='success' onClick={handleDownloadExcel}>
            Download Excel
          </Button>
        </div>

        <div className='table-responsive'>
          {categories.map((category) => (
            <Table className=' mb-0'>
              <thead>
                <tr>
                  <th>{category}</th>
                  <th className='answer-column'></th>
                </tr>
              </thead>
              <tbody>
                {provisionsAnswers?.map((provision) => {
                  if (provision.category === category) {
                    return <ProvisionRow provisionAnswer={provision} onSave={onSaveFeedback} />;
                  }
                  return null;
                })}
              </tbody>
            </Table>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewAnswersModal;
