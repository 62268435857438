import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Input, Label, Nav, NavItem, Row } from 'reactstrap';
import withRouter from '../../Components/Common/withRouter';

//Import Breadcrumb
import Breadcrumbs from '../../Components/Common/Breadcrumb';

import classnames from 'classnames';

//Import Email Topbar
import AgreementToolbar from './aa-toolbar';

//redux
import Spinners from 'Components/Common/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  listAllAdoptionAgreements,
  selectAdoptionAgreement,
  updateAdoptionAgreement,
} from 'slices/thunk';
import { AdoptionAgreement as Agreement } from '../../helpers/firebase_helper';
import { getStatusDescription } from './AAHelper';
import UploadFileModal from './upload-modal';
import ViewAnswersModal from './view-answers-modal';

const AdoptionAgreement = () => {
  document.title = 'Adoption Agreements | PDAI';

  const dispatch = useDispatch<any>();

  const selectorAgreements = createSelector(
    (state: any) => state.AdoptionAgreement,
    (AdoptionAgreement) => ({
      agreements: AdoptionAgreement.agreements,
      loading: AdoptionAgreement.loading,
      error: AdoptionAgreement.error,
      selectedAgreementIds: AdoptionAgreement.selectedAgreementIds,
    }),
  );

  const { agreements, loading, error, selectedAgreementIds }: any = useSelector(selectorAgreements);
  const [filteredAgreements, setFilteredAgreements] = useState<any>([]);

  const [activeTab, setActiveTab] = useState<any>('1');
  const [modal, setModal] = useState<boolean>(false);

  const [showAnswersModal, setShowAnswersModal] = useState(false);
  const [selectedAgreementAnswers, setSelectedAgreementAnswers] = useState<any>(null);

  const handleViewAnswers = (answers: any) => {
    setSelectedAgreementAnswers(answers);
    setShowAnswersModal(true);
  };

  useEffect(() => {
    setFilteredAgreements(agreements);
  }, [agreements]);

  useEffect(() => {
    dispatch(listAllAdoptionAgreements());
  }, [dispatch]);

  const handleSelect = (selectedItems: any) => {
    dispatch(selectAdoptionAgreement(selectedItems));
  };

  const [checkbox, setCheckbox] = useState<boolean>(false);

  const checkFirstCheckbox = () => {
    const checkboxElement: any = document.querySelectorAll(
      '.agreements-list input[type="checkbox"]',
    );
    let isChecked = false;

    checkboxElement.forEach((checkboxElement: any) => {
      if (checkboxElement.checked) {
        isChecked = true;
        return;
      }
    });

    setCheckbox(isChecked);
  };

  const handleArchiveAgreements = () => {
    for (const id of selectedAgreementIds) {
      dispatch(updateAdoptionAgreement({ id: id, updatedData: { status: 'archived' } }));
    }
  };

  const handleCompareAgreements = () => {
    alert('compare ' + selectedAgreementIds);
  };

  const handleModalClose = () => {
    setModal(false);
    dispatch(listAllAdoptionAgreements()); // Refresh the list when the modal closes
  };

  // search
  const handleSearch = (searchQuery: any) => {
    const filteredAgreements = agreements.filter((agreement: Agreement) =>
      agreement.fileName.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredAgreements(filteredAgreements);
  };

  const [displayStatus, setDisplayStatus] = useState('all');
  const filterAgreements = ({ status }: any) => {
    return displayStatus === 'all' || displayStatus === status;
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Plans' breadcrumbItem='PlanPort' />

          <Row>
            <Col xs={12}>
              <Card className='email-leftbar'>
                <Button
                  type='button'
                  color='secondary'
                  className='btn waves-effect waves-light'
                  block
                  onClick={() => {
                    setModal(!modal);
                  }}
                >
                  Add New
                </Button>
                <div className='agreement-status-list mt-4'>
                  <Nav tabs className='nav-tabs-custom' vertical role='tablist'>
                    <NavItem>
                      <a
                        href='#!'
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          setActiveTab('1');
                          setDisplayStatus('all');
                        }}
                      >
                        <i className='mdi mdi-email-outline me-2'></i> All
                        <span className='ml-1 float-end'>{filteredAgreements.length}</span>
                      </a>
                    </NavItem>

                    <NavItem>
                      <a
                        href='#!'
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          setActiveTab('2');
                          setDisplayStatus('active');
                        }}
                      >
                        <i className='mdi mdi-star-outline me-2'></i>Pending Review
                        <span className='ml-1 float-end'>
                          {
                            filteredAgreements.filter(
                              (agreement: Agreement) => agreement.status === 'active',
                            ).length
                          }
                        </span>
                      </a>
                    </NavItem>

                    <NavItem>
                      <a
                        href='#!'
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          setActiveTab('3');
                          setDisplayStatus('reviewed');
                        }}
                      >
                        <i className='mdi mdi-diamond-stone me-2'></i>Reviewed
                        <span className='ml-1 float-end'>
                          {
                            filteredAgreements.filter(
                              (agreement: Agreement) => agreement.status === 'reviewed',
                            ).length
                          }
                        </span>
                      </a>
                    </NavItem>

                    <NavItem>
                      <a
                        href='#!'
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => {
                          setActiveTab('4');
                          setDisplayStatus('archived');
                        }}
                      >
                        <i className='mdi mdi-file-outline me-2'></i>Archived
                        <span className='ml-1 float-end'>
                          {
                            filteredAgreements.filter(
                              (agreement: Agreement) => agreement.status === 'archived',
                            ).length
                          }
                        </span>
                      </a>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <UploadFileModal isOpen={modal} toggle={handleModalClose} />

              <div className='email-rightbar mb-3'>
                {loading ? (
                  <div className='align-items-center text-center p-4'>
                    <Spinners />
                  </div>
                ) : error ? ( // Display error message if there's an error
                  <div className='align-items-center text-center p-4'>
                    <Alert color='danger' role='alert'>
                      Error: {error}
                    </Alert>
                  </div>
                ) : (
                  <Card>
                    {agreements.length > 0 ? (
                      <>
                        {/* Render Email Top Tool Bar */}
                        <AgreementToolbar
                          checkbox={checkbox}
                          setCheckbox={setCheckbox}
                          activeTab={activeTab}
                          handleSearch={handleSearch}
                          handleArchive={handleArchiveAgreements}
                          handleCompare={handleCompareAgreements}
                          status={displayStatus}
                        />
                        <ul className='agreements-list'>
                          {filteredAgreements
                            ?.filter(({ status }: any) => filterAgreements({ status }))
                            .map((agreement: Agreement, key: any) => {
                              return (
                                <li key={key} className={'agreements-row'}>
                                  <div className='agreements-cell checkbox-agreement-container'>
                                    <div className='checkbox-agreement-column'>
                                      <Input
                                        type='checkbox'
                                        className='checkbox-agreement-input'
                                        value={agreement.id}
                                        id={agreement.id}
                                        name='agreementcheckbox'
                                        onChange={(e) => e.target.value}
                                        onClick={(e: any) => {
                                          handleSelect(e.target.value);
                                          checkFirstCheckbox();
                                        }}
                                        checked={selectedAgreementIds.includes(agreement.id)}
                                      />
                                      <Label htmlFor={agreement.id} className='toggle' />
                                    </div>
                                    <div className='checkbox-agreement-column'>
                                      {agreement.fileName}
                                    </div>
                                  </div>
                                  <div className='agreements-cell'>
                                    <span className='tooltip-container'>
                                      {getStatusDescription(agreement.status)}
                                      <span className='tooltip-text'>
                                        Processed at{' '}
                                        {new Date(agreement.processedAt).toDateString()}
                                      </span>{' '}
                                    </span>
                                  </div>
                                  <div className='agreements-cell'>
                                    <button
                                      className='link-button'
                                      onClick={() => window.open(agreement.downloadUrl, '_blank')}
                                    >
                                      Download File
                                    </button>
                                  </div>
                                  <div className='agreements-cell'>
                                    <button
                                      className='link-button'
                                      onClick={() => handleViewAnswers(agreement.answers)}
                                    >
                                      View Provisions
                                    </button>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </>
                    ) : (
                      <div className='align-items-center text-center p-4'>
                        {' '}
                        <i className='mdi mdi-file-document-outline me-2 display-5'></i>{' '}
                        <h4> No File Found </h4>
                        <h5> Add your first file for processing! </h5>
                      </div>
                    )}
                  </Card>
                )}
              </div>
            </Col>
          </Row>
          <ViewAnswersModal
            isOpen={showAnswersModal}
            toggle={() => setShowAnswersModal(false)}
            provisionsAnswers={selectedAgreementAnswers}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdoptionAgreement);
