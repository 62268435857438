import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { getFirebaseBackend } from '../../firebase';

interface UploadFileModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = ({ isOpen, toggle }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
  const [basicAnalysisCompleted, setBasicAnalysisCompleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Add error state

  useEffect(() => {
    if (isOpen) {
      setError(null);
    }
  }, [isOpen]);

  useEffect(() => {
    // If basic analysis is completed and the modal is open, close it after a delay
    if (basicAnalysisCompleted && isOpen) {
      const timeoutId = setTimeout(() => {
        toggle(); // Close the modal using the provided toggle function
        cleanModalStates();
      }, 6000); // Close after 5 seconds

      return () => clearTimeout(timeoutId);
    }
  }, [basicAnalysisCompleted, isOpen, toggle]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const cleanModalStates = () => {
    setSelectedFile(null);
    setUploadProgress(0);
    setUploadCompleted(false);
    setBasicAnalysisCompleted(false);
    setError(null);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const firebaseHelper = getFirebaseBackend();
    try {
      // store the file on a bucket (Firebase Storage).
      await firebaseHelper.uploadFileToStorage(
        `planFiles/${firebaseHelper.getCurrentUser()!.uid}/${selectedFile.name}`,
        selectedFile,
        (progress) => {
          setUploadProgress(progress);
        },
      );

      console.log('File uploaded successfully:', selectedFile.name);

      // update screen properly.
      setUploadCompleted(true);

      // trigger basic analysis.
      try {
        await firebaseHelper.processFile(selectedFile.name);

        console.log('File processed successfully:', selectedFile.name);
        setBasicAnalysisCompleted(true);
      } catch (error) {
        cleanModalStates();
        throw error;
      }
    } catch (error) {
      cleanModalStates();
      // Handle upload error
      setError('Error processing file: ' + (error as any).message.replace('FirebaseError: '));
      console.error('Error uploading file:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} autoFocus={true} centered={true} toggle={toggle} backdrop='static'>
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Upload File </ModalHeader>
        <ModalBody>
          <form>
            {error && ( // Conditionally render error message
              <div>
                <Alert color='danger' role='alert'>
                  {error}
                </Alert>
                <br></br>
              </div>
            )}
            <div className='mb-3'>
              <Input
                type='file'
                accept='.pdf'
                onChange={handleFileChange}
                disabled={uploadCompleted}
              />
            </div>
            <br></br>
            {uploadProgress > 0 && !uploadCompleted && (
              <div>
                <span>
                  - Uploading file... <Spinner className='ms-2 small-spinner' />
                </span>{' '}
                <br></br>
              </div>
            )}
            {uploadProgress > 0 && uploadCompleted && (
              <div>
                <span>
                  - File uploaded successfully. <i className='fas fa-check green-check' />
                </span>
                <br></br>
              </div>
            )}
            {uploadCompleted && !basicAnalysisCompleted && (
              <div>
                <span>
                  - Analyzing the file... (this process may take several minutes)
                  <Spinner className='ms-2 small-spinner' />
                </span>{' '}
                <br></br>
              </div>
            )}
            {uploadCompleted && basicAnalysisCompleted && (
              <div>
                <span>
                  - Analysis complete. <i className='fas fa-check green-check' />
                </span>
                <br></br>
                <br></br>
                <br></br>
                <Alert color='success' role='alert'>
                  Please review the document analysis and let us know if you find any errors or
                  discrepancies.
                </Alert>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          {!uploadCompleted && (
            <Button type='button' color='secondary' onClick={toggle}>
              Cancel
            </Button>
          )}
          {!uploadCompleted && (
            <Button type='button' color='primary' onClick={handleUpload} disabled={!selectedFile}>
              {' '}
              Upload <i className='mdi mdi-upload'></i>{' '}
            </Button>
          )}
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
